import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import PageHero from 'components/PageHero';
import PageBuilder from 'components/PageBuilder/PageBuilder';
import Header from 'components/Header';
import Seo from 'components/Seo';

export default class Pages extends Component {
  render() {
    const {
      data: {
        craft: { entry, entries, globals },
      },
    } = this.props;

    return (
      <>
        <Seo
          title={entry.seoTitle || entry.title}
          description={entry.seoDescription}
        />
        {entry.pageHero.length > 0 && (
          <PageHero pageHero={entry.pageHero} location={this.props.location} />
        )}
        {entry.pageBuilder.length > 0 && (
          <PageBuilder pageBuilder={entry.pageBuilder} />
        )}
      </>
    );
  }
}

export const pageQuery = graphql`
  query PageQueryById($id: [Int]!) {
    craft {
      globals {
        generalContent {
          emailAddress
          phoneNumber
          postalCode
          city
          address1
          address2
        }
      }

      entries {
        ... on Craft_Pages {
          id
          slug
          title
          uri
          level

          children {
            id
            slug
            title
            uri
            level
          }
        }
      }

      entry(id: $id) {
        title
        ... on Craft_Pages {
          seoTitle
          seoDescription
          pageHero {
            ...PageHeroQuery
          }
          pageBuilder {
            ...PageBuilderQuery
          }
        }
      }
    }
  }
`;
